// External dependensieseact
import React from 'react';

// Local dependensieseact
import { IconProps } from './types';

export default function ArticlesMessageIcon({
  width = 181,
  height = 168,
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 181 168"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M167.652 138.13C167.496 138.6 167.287 139.043 167.13 139.382C166.948 139.695 166.817 139.904 166.817 139.904C166.06 141.209 165.565 142.017 164.861 142.461C164.992 142.33 165.095 142.174 165.174 142.043C166.504 139.826 164.469 136.33 160.347 129.469C156.825 123.626 153.409 119.113 150.93 116.087C152.965 112.878 155.547 108.156 157.479 102.078C157.764 101.191 162.538 85.3041 157.999 67.6694C152.783 47.3998 136.556 29.7129 116.364 21.8085C88.6346 10.9042 57.7216 20.0868 38.4172 39.1824C37.5823 40.0173 34.9997 42.6259 31.8955 46.7477C36.6432 39.1302 41.3388 34.3563 42.539 33.1824C61.8432 14.0867 92.7562 4.90417 120.434 15.7302C121.87 16.278 153.174 29.165 162.069 61.5911C167.6 81.7824 161.73 99.5476 160.869 102.026C159.122 107.061 157.06 111.052 155.391 113.869C158.887 119.452 161.599 123.626 163.374 126.365C163.426 126.365 168.774 134.53 167.652 138.13Z"
        fill="#FF7300"
      />
      <path
        d="M160.322 129.418C156.8 123.574 153.382 119.061 150.905 116.035C152.94 112.826 155.522 108.105 157.452 102.026C157.739 101.139 162.513 85.2525 157.974 67.6176C152.756 47.3481 136.53 29.6611 116.339 21.7568C88.635 10.9047 57.7219 20.0873 38.4175 39.1829C37.5828 40.0178 35.0002 42.6264 31.8958 46.7482C24.3045 56.8176 13.5045 75.9655 16.7655 100.513C20.7568 130.122 43.2959 153.653 69.3045 160.54C76.0089 162.313 96.9046 166.695 117.435 155.531C126.043 150.861 131.809 144.939 135.253 140.818C138.33 141.548 143.001 142.513 148.817 143.166C157.27 144.078 162.879 144.679 164.861 142.461C164.992 142.331 165.096 142.174 165.174 142.044C166.479 139.774 164.444 136.305 160.322 129.418Z"
        fill="#84C6C3"
      />
      <path
        d="M38.5474 100.122C38.2604 106.67 43.4779 112.2 49.53 112.878C55.0082 113.505 59.6519 110.009 61.8432 106.279C62.417 105.313 64.3475 101.87 63.4084 97.6959C62.2345 92.4784 57.1475 88.5133 51.6953 88.3306C45.4084 88.122 38.8605 93.0525 38.5474 100.122Z"
        fill="white"
      />
      <path
        d="M70.9996 100.122C70.7126 106.67 75.93 112.2 81.9821 112.878C87.4604 113.505 92.104 110.009 94.2953 106.279C94.8692 105.313 96.7997 101.87 95.8605 97.6959C94.6866 92.4784 89.5997 88.5133 84.1474 88.3306C77.8343 88.122 71.3126 93.0525 70.9996 100.122Z"
        fill="white"
      />
      <path
        d="M103.453 100.122C103.166 106.67 108.382 112.2 114.436 112.878C119.914 113.505 124.557 110.009 126.748 106.279C127.321 105.313 129.253 101.87 128.313 97.6959C127.14 92.4784 122.053 88.5133 116.6 88.3306C110.288 88.122 103.74 93.0525 103.453 100.122Z"
        fill="white"
      />
    </svg>
  );
}
