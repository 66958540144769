// External dependensies
import { Div, Text } from 'atomize';
import { Link, graphql, useStaticQuery } from 'gatsby';
import React from 'react';

// Local dependensies
import getUniqueValue from '../../lib/getUniqueValue';

export default function FilterBlock() {
  const data = useStaticQuery(queryCategory);
  const { nodes } = data.allArticle;
  const uniqueElements: string[] = getUniqueValue(nodes, 'category').filter(
    (el) => el,
  );

  return (
    <Div
      w="100%"
      p="24px 20px"
      bg="bg_dropdown"
      rounded="10px"
      d="flex"
      flexDir={{ xs: 'column', sm: 'row' }}
      style={{ gap: '16px' }}
      flexWrap="wrap"
      m={{ b: '24px', x: '15px' }}
    >
      {uniqueElements.map((el, index) => (
        <Link key={index} to={`/articles/${el.toLowerCase()}`}>
          <Text
            textWeight="600"
            m={{ r: '20px' }}
            textColor="text_color_for_teaser"
          >
            {randomIcon(el, index)}
          </Text>
        </Link>
      ))}
    </Div>
  );
}

const icons = ['✂️', '💡', '📢', '⚡️'];

const randomIcon = (text: string, index: number) => {
  return `${icons[index]} #${text}`;
};

export const queryCategory = graphql`
  {
    allArticle {
      nodes {
        category
      }
    }
  }
`;
