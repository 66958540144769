// External depentensies
import React from 'react';

// Local dependensieseact
import { IconProps } from './types';

export default function ArticlesStarsIcon({
  width = 177,
  height = 177,
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 177 177"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M49.6438 60.1423C45.6265 63.6053 38.5308 66.8 31.2785 67.3637C24.5481 67.9005 16.67 66.2362 15.8091 68.8134C15.6786 69.2161 15.7046 69.6723 15.9656 70.236C18.1047 75.1757 30.8352 73.1085 39.2091 77.8869C52.1742 85.2962 54.2872 109.215 55.3046 120.947C56.0352 129.188 56.0612 141.94 58.409 142.182C58.7481 142.208 59.0351 141.994 59.1656 141.886C61.9568 139.577 63.3394 93.1888 70.7742 83.632C72.5482 81.35 75.2091 77.4307 80.1655 74.4777C89.3743 68.9744 100.592 69.9409 100.8 67.0951C100.826 66.8805 100.774 66.6119 100.487 66.1557C97.4872 61.5651 86.1134 64.8939 79.6177 61.1355C67.1742 53.941 64.0698 15.7938 63.3655 15.4449C62.9221 15.23 62.6611 15.23 62.3481 15.2838C56.4785 16.1159 64.0178 47.713 49.6438 60.1423Z"
        fill="#51A8A3"
      />
      <path
        d="M131.923 60.0346C127.043 55.7125 118.591 58.6923 114.13 53.1891C113.688 52.6521 113.426 52.1689 113.478 51.6589C113.791 48.4375 125.583 51.0146 131.766 45.1355C139.852 37.4308 134.791 18.5587 138.444 17.9679C138.704 17.9144 138.913 17.9949 139.279 18.129C145.174 20.411 139.304 38.1557 146.765 44.2226C150.94 47.6051 158.87 46.9878 163.644 51.2562C163.931 51.4978 164.323 51.9005 164.295 52.3837C164.166 54.9071 153.182 52.8937 147.027 58.2629C138.418 65.7795 142.096 85.2154 139.592 85.3229C139.539 85.3229 139.436 85.3229 139.252 85.2154C134.975 82.9068 138.678 66.0479 131.923 60.0346Z"
        fill="#E090C7"
      />
      <path
        d="M108.758 114.933C117.366 107.846 111.027 87.1753 117.339 85.484C117.679 85.4035 117.992 85.3229 118.384 85.4035C123.339 86.6115 116.008 110.128 125.087 118.021C132.521 124.463 148.696 118.504 149.348 121.806C149.4 121.994 149.348 122.155 149.297 122.369C148.305 126.799 136.853 126.504 130.279 130.799C116.349 139.926 120.782 172.033 116.687 172.033C116.609 172.033 116.531 172.006 116.374 171.952C109.487 169.806 116.087 143.873 104.426 132.383C96.9134 125 83.2177 124.973 83.557 121.108C83.557 120.973 83.609 120.705 83.87 120.249C87.1047 114.638 101.557 120.893 108.758 114.933Z"
        fill="#EACB86"
      />
    </svg>
  );
}
