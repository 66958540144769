/* External dependencies */
import { Col, Container, Div, Row } from 'atomize';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

/* Local dependencies */
import { getLabelColor } from '../../lib/getLabelColor';
import ArticleCard from '../articleCard/ArticleCard';
import ArticleTitleBlock from '../articleTitleBlock/ArticleTitleBlock';
import FilterBlock from '../filterBlock/FilterBlock';

export default function Articles() {
  const data = useStaticQuery(query);
  const { nodes } = data?.allArticle || '';

  return (
    <Container>
      <main>
        <Div data-aos="zoom-in-up" data-aos-duration="500">
          <ArticleTitleBlock />
        </Div>
      </main>
      <Row d="flex" m={{ x: '-20px' }}>
        {nodes &&
          nodes?.map((article, index) => {
            const image =
              article.heroMedia?.imageUrl?.childImageSharp?.gatsbyImageData;
            const color = getLabelColor(article.category);
            const url = `/articles/${article.slug}`;

            const isFilterBlock = index === 0;
            if (index % 4 === 0) {
              return (
                <React.Fragment key={index}>
                  <Col
                    d="flex"
                    size={{ xs: '12', md: '6', lg: '12' }}
                    data-aos="zoom-in-up"
                    data-aos-duration="800"
                  >
                    <ArticleCard
                      date={article.createDate}
                      image={image}
                      flexDir="row"
                      textLabel={article.category}
                      linkToArticle={url}
                      textSizeTitle="34px"
                      paddingCard="30px"
                      color={color}
                      justifyFlex="space-between"
                      bgColor="gray"
                      cardAlign="center"
                      linkLeft="26px"
                    >
                      {article.title}
                    </ArticleCard>
                  </Col>
                  {isFilterBlock && <FilterBlock />}
                </React.Fragment>
              );
            } else {
              return (
                <Col
                  data-aos="zoom-in-up"
                  data-aos-duration="800"
                  size={{ xs: '12', md: '6', lg: '4' }}
                  d="flex"
                  key={index}
                >
                  {
                    <ArticleCard
                      date={article.createDate}
                      image={image}
                      flexDir="column-reverse"
                      textLabel={article.category}
                      linkToArticle={url}
                      textSizeTitle="display1"
                      paddingCard="24px"
                      color={color}
                      bgColor="gray"
                      justifyFlex="start"
                      cardAlign="start"
                      linkLeft="24px"
                    >
                      {article.title}
                    </ArticleCard>
                  }
                </Col>
              );
            }
          })}
      </Row>
    </Container>
  );
}

export const query = graphql`
  {
    allArticle(sort: { fields: createDate, order: DESC }) {
      nodes {
        articleType
        category
        createDate
        heroMedia {
          imageUrl {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        readTimeMinute
        slug
        synopsis
        title
        id
      }
    }
  }
`;
