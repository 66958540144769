//external dependensies
import { Div, Text } from 'atomize';
import { Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { useSelector } from 'react-redux';

//local dependensies
import ArticlesMessageIcon from '@svg/ArticlesMessageIcon';
import ArticlesStarsIcon from '@svg/ArticlesStarsIcon';
import './style.scss';

export default function ArticleTitleBlock() {
  const { isBanner } = useSelector((state) => state?.banner);

  return (
    <Div
      bg="linear-gradient(90deg, rgba(140, 250, 199, 0.2) 0%, rgba(207, 214, 250, 0.2) 100%, rgba(207, 214, 250, 0.2) 100%)"
      rounded={{ xs: '0', md: '20px' }}
      d="flex"
      justify={{ xs: 'start', lg: 'space-between' }}
      align="center"
      p={{ y: '54px', x: { xs: '24px', lg: '74px' } }}
      m={{
        x: { xs: '-25px', md: '0' },
        t: { xs: '100px', lg: isBanner ? '200px' : '100px' },
        b: '20px',
      }}
    >
      <Div d={{ xs: 'none', lg: 'block' }}>
        <ArticlesStarsIcon />
      </Div>
      <Div
        textAlign={{ xs: 'start', md: 'center' }}
        maxW={{ xs: '100%', lg: '400px' }}
      >
        <Text
          d="flex"
          justify={{ xs: 'start', md: 'center' }}
          m={{ b: '24px' }}
          textSize={{ xs: 'display', sm: 'subtitle', lg: 'downloadAppTitle' }}
          textWeight="700"
          tag="span"
          textColor="text_color_for_teaser"
        >
          Medcheck
          <Text
            tag="span"
            d="inline-block"
            m={{ l: '10px' }}
            className="medcheckMagazineTitle"
          >
            <Trans>magazine</Trans>
          </Text>
        </Text>
        <Text
          tag="p"
          textColor="text_color_for_teaser"
          textSize={{ xs: 'body', sm: 'medium', lg: 'display1' }}
        >
          <Trans>downloadAppDescription</Trans>
        </Text>
      </Div>
      <Div d={{ xs: 'none', lg: 'block' }}>
        <ArticlesMessageIcon />
      </Div>
    </Div>
  );
}
